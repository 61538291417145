import Vue from 'vue'
import VueRouter from 'vue-router'
import _import from './import'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: _import('/Home')
  },
  {
    path: '/About',
    name: 'About',
    component: _import('/About')
  },
  {
    path: '/Service',
    name: 'Service',
    component: _import('/Service')
  },
  {
    path: '/Industry',
    name: 'Industry',
    component: _import('/Industry')
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: _import('/Contact')
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.NODE_ENV === 'production' ? '/thjy' : '',
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
  // to and from are both route objects. must call `next`.
})

export default router
